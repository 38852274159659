import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useBreakpoint = () => {
  const theme = useTheme();
  return {
    isXs: useMediaQuery(theme.breakpoints.only("xs")),
    isSm: useMediaQuery(theme.breakpoints.only("sm")),
    isMd: useMediaQuery(theme.breakpoints.only("md")),
    isLg: useMediaQuery(theme.breakpoints.only("lg")),
    isXl: useMediaQuery(theme.breakpoints.only("xl")),
    upSm: useMediaQuery(theme.breakpoints.up("sm")),
    downMd: useMediaQuery(theme.breakpoints.down("md")),
  };
};

export default useBreakpoint;
