import { GridFilterItem } from "@mui/x-data-grid-pro";
import { AxiosService, UbicoAPIResponse } from "../api-provider";
import {
  NylasAccount,
  NylasAccountDetails,
  NylasAccountMetrics,
  NylasEmail,
  NylasFolder,
  NylasPaginatedData,
  NylasSmartCompose,
  NylasThreadEmail,
  PaginatedData,
  Person,
} from "../constants/data-types";
import { buildFilter } from "../constants/filters";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";
import { LeaderBoardOrderEnum } from "../pages/overview/constants/leaderboard";

const getSyncURL = async (
  email?: string
): Promise<UbicoAPIResponse<object>> => {
  try {
    const params = email ? { email: email } : {};
    const resp = await AxiosService.get(
      `${API_PATH}/emails/authenticate-url/`,
      { params: params }
    );
    return { error: false, data: resp.data.url, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getSyncedAccountById = async (
  id: number
): Promise<UbicoAPIResponse<NylasAccount>> => {
  try {
    const accounts = await AxiosService.get(`${API_PATH}/emails/${id}/`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getSyncedAccounts = async (): Promise<
  UbicoAPIResponse<Array<NylasAccount>>
> => {
  try {
    const accounts = await AxiosService.get(`${API_PATH}/emails/`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateSyncedAccount = async (
  id: number,
  data: any
): Promise<UbicoAPIResponse<object>> => {
  try {
    const accounts = await AxiosService.patch(
      `${API_PATH}/emails/${id}/`,
      data
    );
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteSyncedAccount = async (
  id: number
): Promise<UbicoAPIResponse<object>> => {
  try {
    const accounts = await AxiosService.delete(`${API_PATH}/emails/${id}/`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const createFolder = async (
  id: number,
  folder_name: string
): Promise<UbicoAPIResponse<NylasFolder>> => {
  try {
    const accounts = await AxiosService.post(
      `${API_PATH}/emails/${id}/folders/`,
      { folder_name: folder_name }
    );
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const sweepFolders = async (id: number): Promise<UbicoAPIResponse<object>> => {
  try {
    const resp = await AxiosService.post(
      `${API_PATH}/emails/${id}/sweep-folders/`
    );
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getFolders = async (
  id: number,
  limit = 50,
  nextCursor?: string
): Promise<UbicoAPIResponse<NylasPaginatedData<NylasFolder>>> => {
  try {
    const params = { limit: limit, page_token: nextCursor };
    const resp = await AxiosService.get(`${API_PATH}/emails/${id}/folders/`, {
      params: params,
    });
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getEmailMetrics = async (
  nylas_id: number
): Promise<UbicoAPIResponse<NylasAccountMetrics>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/metrics/`;

    const resp = await AxiosService.get(url);
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getEmailDetails = async (
  nylas_id: number
): Promise<UbicoAPIResponse<NylasAccountDetails>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/details/`;

    const resp = await AxiosService.get(url);
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getEmailsLeaderBoard = async (
  order: LeaderBoardOrderEnum
): Promise<UbicoAPIResponse<NylasAccount[]>> => {
  try {
    const url = `${API_PATH}/emails/metrics/leaderboard/`;
    const params = { order: order };

    const resp = await AxiosService.get(url, { params: params });
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getEmails = async (
  workspace_id: number,
  page: number,
  pageSize: number,
  filters?: Array<GridFilterItem>,
  searchFilters?: Array<any>
): Promise<UbicoAPIResponse<PaginatedData<NylasEmail>>> => {
  try {
    const url = `${API_PATH}/workspaces/${workspace_id}/threads/`;
    const params = {};

    // Add search filters on url
    if (searchFilters?.length > 0) params["search"] = searchFilters.join(", ");

    // Add filters on url
    filters?.map((filter: GridFilterItem) => {
      if (filter.field && filter.operator)
        buildFilter(params, filter.field, filter.operator, filter.value);
    });

    // Add pagination
    if (page !== null && pageSize !== null) {
      params["page"] = page;
      params["page_size"] = pageSize;
    }

    const emails = await AxiosService.get(url, { params: params });
    return { error: false, data: emails.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getPersonByResponseId = async (
  nylas_id: number,
  response_id: number
): Promise<UbicoAPIResponse<Person>> => {
  try {
    const people = await AxiosService.get(
      `${API_PATH}/emails/${nylas_id}/threads/${response_id}/person/`
    );
    return { error: false, data: people.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getThreadEmails = async (
  nylas_id: number,
  response_id: number
): Promise<UbicoAPIResponse<NylasThreadEmail[]>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/threads/${response_id}/`;

    const thread = await AxiosService.get(url);
    return { error: false, data: thread.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateThreadEmail = async (
  nylas_id: number,
  response_id: number,
  data: any
): Promise<UbicoAPIResponse<NylasEmail>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/threads/${response_id}/`;

    const thread = await AxiosService.patch(url, data);
    return { error: false, data: thread.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const sendEmail = async (
  nylas_id: number,
  response_id: number,
  subject: string,
  body: string,
  to: { email: string; name?: string },
  cc: { email: string; name?: string },
  bcc: { email: string; name?: string },
  reply_to_message_id: string
): Promise<UbicoAPIResponse<NylasThreadEmail>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/threads/${response_id}/send/`;

    const message = await AxiosService.post(url, {
      subject,
      body,
      to,
      cc,
      bcc,
      reply_to_message_id,
    });
    return { error: false, data: message.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const composeReply = async (
  nylas_id: number,
  reply_to_message_id: string,
  prompt: string
): Promise<UbicoAPIResponse<NylasSmartCompose>> => {
  try {
    const url = `${API_PATH}/emails/${nylas_id}/compose/reply/`;

    const message = await AxiosService.post(url, {
      message_id: reply_to_message_id,
      prompt,
    });
    return { error: false, data: message.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error ||
        error.response?.data?.details ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const NylasService = {
  getSyncURL,
  getSyncedAccounts,
  getSyncedAccountById,
  updateSyncedAccount,
  deleteSyncedAccount,
  createFolder,
  getEmailsLeaderBoard,
  sweepFolders,
  getEmailMetrics,
  getEmails,
  getThreadEmails,
  updateThreadEmail,
  sendEmail,
  getFolders,
  getPersonByResponseId,
  composeReply,
  getEmailDetails,
};

export default NylasService;
