import ChecklistIcon from "@mui/icons-material/Checklist";
import { Icon } from "@mui/material";
import TasksCountChip from "../components/tasks/TasksCountChip";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { Tasks } from "../loadables";
import { Route } from "./routeInterface";

export const taskRoutes: Array<Route> = [
  {
    key: "tasks-route",
    label: "Tasks",
    path: "tasks",
    enabled: true,
    component: Tasks,
    isTopTab: true,
    order: 9,
    tabInfo: {
      id: "tasks",
      startIcon: <Icon component={ChecklistIcon} fontSize="small" />,
      endIcon: <TasksCountChip />,
      alwaysShowEndIcon: true,
    },
    leastRole: COLLABORATOR_ROLE,
  },
];
