import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { Outlet, useLocation } from "react-router-dom";
import { Account } from "../constants/data-types";
import { RootState } from "../redux/store";

interface ProtectedRouteProps {
  redirectPath?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectPath = "/login",
}: ProtectedRouteProps) => {
  const location = useLocation();

  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const account: Account = useSelector((state: RootState) => state.account);

  if (!is_logged_in && location.pathname !== "/login") {
    return <Navigate to={redirectPath} replace />;
  }

  if (
    is_logged_in &&
    account.completed_account_setup &&
    location.pathname === "/"
  ) {
    return <Navigate to={`/overview`} replace />;
  }

  if (
    is_logged_in &&
    !account.completed_account_setup &&
    location.pathname !== "/setup"
  ) {
    return <Navigate to={`/setup`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
