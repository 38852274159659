import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PushNotification = {
  id: string;
  open: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
  task_id?: string;
};

type PushNotificationReducer = {
  notifications: PushNotification[];
};

const initialState: PushNotificationReducer = {
  notifications: [],
};

export const pushNotificationSlice = createSlice({
  name: "pushNotification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<PushNotification>) => {
      state.notifications.push(action.payload); // Add notification to the state
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload // Remove based on notification id
      );
    },
  },
});

export const { addNotification, removeNotification } =
  pushNotificationSlice.actions;

export default pushNotificationSlice.reducer;
