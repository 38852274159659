import { TextField } from "@mui/material";
import { darken, styled } from "@mui/material/styles";

export const UbicoTextField = styled(TextField)(({ theme }) => ({
  label: {
    color: theme.palette.text.primary,
  },
  color: theme.palette.primary.main,
  "&:hover": {
    color: darken(theme.palette.primary.main, 0.2),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.disabled,
    "&.Mui-error": {
      color: theme.palette.error.main,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: theme.palette.background.paper,
    fontSize: "0.8rem",
    "& fieldset": {
      borderColor: theme.palette.divider,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-disabled": {
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
    }
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
}));
