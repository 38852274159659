import { CheckCircle, Error } from "@mui/icons-material";
import { Alert, AlertColor, CircularProgress, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { GENERIC_ERROR_MESSAGE } from "../../../constants/snackbar-messages";
import { WebsocketType } from "../../../constants/websocket";
import { useWebSocketContext } from "../../../hooks/useWebSocketService";

interface ProgressNotificationSnackbarProps extends CustomContentProps {
  task_id?: string
  default_total?: number
  callback?(success: boolean): void
}

const LoadingIcon: FC = () => {
  const theme = useTheme()
  return (
    <CircularProgress sx={{color: theme.palette.common.white}} size={20}/>
  )
}

const ProgressNotificationSnackbar = React.forwardRef<HTMLDivElement, ProgressNotificationSnackbarProps>((props, ref) => {
    const { message, id, default_total = 0, task_id = null, callback = null } = props
    const theme = useTheme();

    const { closeSnackbar } = useSnackbar()

    const { lastJsonMessage } = useWebSocketContext();

    const [severity, setSeverity] = useState<AlertColor>("info"); // Default severity
    const [icon, setIcon] = useState(<LoadingIcon/>); // Icon state

    const [completed, setCompleted] = useState(false)
    const [progress, setProgress] = useState<number | null>(0);
    const [total, setTotal] = useState<number | null>(default_total);

    const [snackMessage, setSnackMessage] = useState(message)

    const updateProgress = (progress: number, total: number) => {
      setProgress(progress);
      setTotal(total);

      // Reset to info state while in progress
      setSeverity("info");
      setIcon(<LoadingIcon/>);
      setCompleted(false)
    }

    const handleTaskComplete = (success: boolean) => {
      if (success) {
        // Successful completion
        setProgress(total) // So that progress bar is 100%
        setSeverity("success");
        setIcon(<CheckCircle />);
        setCompleted(true)
      } else {
        // Handle failure
        setSeverity("error");
        setIcon(<Error />);
        setCompleted(true)
        setSnackMessage(GENERIC_ERROR_MESSAGE)
      }
      if(callback) callback(success)
      // Automatically close snackbar when task completes
      const timer = setTimeout(() => closeSnackbar(id), 3000);

      // Cleanup timeout on unmount or state change
      return () => clearTimeout(timer);
    }

    useEffect(() => {
      if (lastJsonMessage) {
        const data = lastJsonMessage
        const { task_id: message_task_id, message, progress, total, type, success = false } = data

        // Ensure we are handling only updates for the task in the current component
        if (!message_task_id || task_id !== message_task_id) return

        if(message) setSnackMessage(message)

        switch (type) {
          case WebsocketType.TaskProgress: {
            updateProgress(progress, total)
            break
          } case WebsocketType.TaskComplete: {
            handleTaskComplete(success)
            break
          } default:
            break
        }
      }
    }, [lastJsonMessage]);

    return (
      <SnackbarContent ref={ref}>
        <Alert
          severity={severity}
          variant="filled"
          icon={icon}
          sx={{minWidth: 300, color: theme.palette.common.white, alignItems: 'center'}}
        >
          <Stack justifyContent={'center'}>
            {snackMessage}

            {(task_id && !completed) &&(
              <LinearProgress
                variant="determinate"
                value={total > 0 ? (progress / total) * 100 : 0}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly transparent background
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.common.white, // White progress bar
                  },
                }}
              />
            )}
            <Typography variant="caption">{progress} / {total}</Typography>
          </Stack>
        </Alert>
      </SnackbarContent>
    );
  }
)

export default ProgressNotificationSnackbar;
