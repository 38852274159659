import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SNACKBAR_DEFAULT_DURATION = 5000;

type SnackbarState = {
  open: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
  task_id?: string;
};

const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "success",
  task_id: null,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<SnackbarState>) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.task_id = action.payload.task_id;
    },
    closeSnackbar: (state) => {
      state.open = false;
      state.task_id = null;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
