import { SensorOccupied } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import {
  AutomatedSegment,
  DataDrivenSegment,
  NewSegment,
  SegmentDetails,
  Segments,
} from "../loadables";
import { Route } from "./routeInterface";

export const segmentRoutes: Array<Route> = [
  {
    key: "segments-route",
    label: "segments",
    path: "segments",
    enabled: true,
    component: Segments,
    isTopTab: true,
    order: 4,
    tabInfo: {
      id: "segments",
      startIcon: <Icon component={SensorOccupied} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "new-segment-route",
    label: "New Segment",
    path: "segments/new",
    enabled: true,
    component: NewSegment,
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "data-driven-segment-route",
    label: "Data-driven Segment",
    path: "segments/new/data-driven",
    enabled: true,
    component: DataDrivenSegment,
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "automated-segment-route",
    label: "Automated Segment",
    path: "segments/new/automated",
    enabled: true,
    component: AutomatedSegment,
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "segment-details-route",
    label: "Segment details",
    path: "segments/:id",
    enabled: true,
    component: SegmentDetails,
    leastRole: COLLABORATOR_ROLE,
  },
];
