import { Business } from "@mui/icons-material";
import { Icon } from "@mui/material";
import ComingSoonFeatureChip from "../components/custom/chips/ComingSoonFeatureChip";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { Route } from "./routeInterface";

export const visitorRoutes: Array<Route> = [
  {
    key: "visitor-route",
    label: "Visitors",
    path: "visitors",
    enabled: false,
    component: null,
    isTopTab: true,
    order: 3,
    tabInfo: {
      id: "inbox",
      startIcon: <Icon component={Business} fontSize="small" />,
      endIcon: <ComingSoonFeatureChip/>,
      alwaysShowEndIcon: true
    },
    leastRole: COLLABORATOR_ROLE,
  }
];
