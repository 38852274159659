import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Chip,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { UserRoles } from "../../constants/user-roles";
import { RootState } from "../../redux/store";
import { isUserAllowed } from "../../utils/user-role-utils";

export const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
}));

interface UbicoCardProps extends CardProps {
  children: ReactNode;
  cardTitle?: ReactNode | string;
  cardSubtitle?: ReactNode | string;
  headerAction?: ReactNode;
  headerAvatar?: ReactNode;
  leastRole?: UserRoles;
  disabled?: boolean;
}

const UbicoCard: React.FC<UbicoCardProps> = (props) => {
  const {
    children,
    cardTitle,
    cardSubtitle,
    headerAction,
    headerAvatar,
    leastRole = null,
    disabled = false,
    ...otherProps
  } = props;

  const theme = useTheme();

  const { user_role } = useSelector((state: RootState) => state.profile);

  let permissionDenied = false;

  if (leastRole) {
    permissionDenied = !isUserAllowed(user_role, leastRole);
  }

  return (
    <StyledCard
      sx={{
        pointerEvents: disabled || permissionDenied ? "none" : "initial",
        opacity: disabled || permissionDenied ? 0.7 : 1,
      }}
      {...otherProps}
      elevation={1}
    >
      {(headerAction || headerAvatar || cardTitle || cardSubtitle) && (
        <CardHeader
          title={
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack spacing={theme.spacing(1)}>
                {typeof cardTitle === "string" ? (
                  <Typography variant="h6">{cardTitle}</Typography>
                ) : (
                  cardTitle
                )}
                {typeof cardSubtitle === "string" ? (
                  <Typography variant="caption">{cardSubtitle}</Typography>
                ) : (
                  cardSubtitle
                )}
              </Stack>
              {permissionDenied && (
                <Chip
                  color="warning"
                  size="small"
                  variant="outlined"
                  label={"You do not have the required permissions to edit."}
                />
              )}
            </Stack>
          }
          action={!disabled && !permissionDenied && headerAction}
          avatar={headerAvatar}
          sx={{ pb: 0, mb: 0 }}
        />
      )}
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default UbicoCard;
