import { Container, SvgIcon, Typography, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { ReactComponent as UbicoLogo } from "../assets/logos/ubico.svg";

const UnsupportedMobileView: FC = (): ReactElement => {
  const theme = useTheme();
  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <SvgIcon
        component={UbicoLogo}
        sx={{ color: theme.palette.text.primary, height: 150, width: 150 }}
        inheritViewBox
      />
      <Typography variant="h4" gutterBottom>
        Your account has been created.
      </Typography>
      <Typography variant="body1" paragraph>
        Our web app is currently not supported on mobile.
        Please use a desktop computer to log in to your Ubico account.
      </Typography>
    </Container>
  );
};

export default UnsupportedMobileView;
