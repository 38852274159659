import { Groups2 } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { People, PeopleDetails } from "../loadables";
import { Route } from "./routeInterface";

export const peopleRoutes: Array<Route> = [
  {
    key: "people-route",
    label: "People",
    path: "people/",
    enabled: true,
    component: People,
    isTopTab: true,
    order: 3,
    tabInfo: {
      id: "people",
      startIcon: <Icon component={Groups2} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "people-details-route",
    label: "People details",
    path: "people/:id",
    enabled: true,
    component: PeopleDetails,
    leastRole: COLLABORATOR_ROLE,
  },
];
