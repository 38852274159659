import { Dashboard } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { Overview } from "../loadables";
import { Route } from "./routeInterface";

export const overviewRoutes: Array<Route> = [
  {
    key: "overview-route",
    label: "Overview",
    path: `overview`,
    enabled: true,
    component: Overview,
    isTopTab: true,
    order: 0,
    tabInfo: {
      id: "overview",
      startIcon: <Icon component={Dashboard} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
];
