import { Campaign } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { CampaignDetails, Campaigns, NewCampaign } from "../loadables";
import { Route } from "./routeInterface";

export const campaignRoutes: Array<Route> = [
  {
    key: "campaigns-route",
    label: "campaigns",
    path: "campaigns",
    enabled: true,
    component: Campaigns,
    isTopTab: true,
    order: 5,
    tabInfo: {
      id: "campaigns",
      startIcon: <Icon component={Campaign} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "new-campaign-route",
    label: "New Campaign",
    path: "campaigns/new",
    enabled: true,
    component: NewCampaign,
    leastRole: COLLABORATOR_ROLE,
  },
  {
    key: "campaign-details-route",
    label: "Campaign details",
    path: "campaigns/:id",
    enabled: true,
    component: CampaignDetails,
    leastRole: COLLABORATOR_ROLE,
  },
];
