// WebSocketContext.tsx
import { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { REACT_APP_BACKEND_URL } from '../constants';
import { RootState } from '../redux/store';

interface WebSocketContextProps {
  sendJsonMessage(message: any): void
  lastJsonMessage: any
}

const WebSocketContext = createContext<WebSocketContextProps>(
  {} as WebSocketContextProps,
);

// Custom hook to access WebSocket context
export const useWebSocketContext = () => useContext(WebSocketContext);

// WebSocket provider component
export const WebSocketProvider = ({ children }) => {
  const { is_logged_in } = useSelector((state: RootState) => state.auth)
  const wsUrl = REACT_APP_BACKEND_URL.replace("https://", "wss://").replace("http://", "ws://")
  const { sendMessage, sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    is_logged_in ? `${wsUrl}/ws/` : null, {
    shouldReconnect: () => is_logged_in,
    reconnectInterval: 3000, // Reconnect every 3 seconds if disconnected
  });

  const sendHealthCheck = () => {
    if (readyState === ReadyState.OPEN) {
      sendMessage('ping');
    }
  };

  useEffect(() => {
    if (!is_logged_in) {
      getWebSocket()?.close()
      return; // Stop running effect if not logged in
    }
    const pingInterval = setInterval(() => sendHealthCheck(), 30000); // Ping every 30 seconds

    return () => {
      clearInterval(pingInterval); // Cleanup on unmount)
    };
  }, [getWebSocket, readyState, sendJsonMessage, is_logged_in]);

  return (
    <WebSocketContext.Provider value={{ sendJsonMessage, lastJsonMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};
